import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";

function System(props) {
    const game_context_data = useContext(GameContext);
    const { t } = useTranslation();
    const [rows,setRows] = useState([]);

    const exitFromSession = () => {
        localStorage.clear();
        window.location.reload();
    };

    window.Clipboard = (function(window, document, navigator) {
        var textArea,
            copy;

        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.value = text;
            document.body.appendChild(textArea);
        }

        function selectText() {
            var range,
                selection;

            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }

        function copyToClipboard() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }

        copy = function(text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
        };

        return {
            copy: copy
        };
    })(window, document, navigator);

    function clipboardCopy(text) {
        window.Clipboard.copy(text);
    }

    useEffect(() => {
        const link_rows = [];
        for (const [key, value] of Object.entries(game_context_data.game.players)) {
            let current_location = window.location.protocol+'//'+window.location.hostname+(window.location.port?":"+window.location.port:"");
            let link = ""+current_location+"?session_id="+game_context_data.game.session_id+"&player_id="+key;
            link_rows.push(
                <div key={key} className={value.gender === 1 && "player_item btn-info" || "player_item btn-pink"}>
                    <div className="row align-items-center p-2">
                        <div className="col-4">
                            <div>{value.name}</div>
                        </div>
                        <div className="col-8 text-end">
                            <button className="btn btn-primary" onClick={() => clipboardCopy(link)}>{t('copy')}</button>
                        </div>
                    </div>
                </div>
            );
        }
        setRows(link_rows);
    },[]);

    return(
        <div className="row g-3 align-items-center">
            <div className="btn btn-primary fw-bold mt-4">
                {t('system')}
            </div>
            {rows}

            <hr />
            <h5 className="text-center">
                {t('other_functions')}
            </h5>
            <div>
                <a className="btn btn-primary" onClick={exitFromSession}>{t('exit_from_session')}</a>
            </div>
        </div>
    );

}


export default System;
