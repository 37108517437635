import React, {useReducer,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import ModalForm from "./ModalForm";
import {Row} from "react-bootstrap";
import {PlusLg} from "react-bootstrap-icons";

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function AddEditEffect(props) {
    const game_context_data = useContext(GameContext);
    const {t} = useTranslation();
    const [form_data, setFormData] = useReducer(formReducer, {});


    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const handleForm = () => {
        let effect_data = Object.assign({}, form_data);
        let effectModifyObject = {};

        if (effect_data.battle_count === undefined) {
            effect_data.battle_count = 1;
        }

        if (effect_data.mod === undefined ) {
            effect_data.mod = -5;
        }

        if (props.effect !== undefined && props.effect.id > 0) {
            effect_data.effect_id = props.effect.id;
            if (effect_data.mod === undefined) {
                effect_data.mod = props.effect.mod;
            }

            effectModifyObject = {
                "method": "updateEffect",
                "data": effect_data
            };
        } else {
            effect_data.player_id = game_context_data.game.current_player_id;
            effectModifyObject = {
                "method": "addEffect",
                "data": effect_data
            };
        }

        game_context_data.send_to_socket(effectModifyObject);
    }

    const form = <div>
        <form className="text-center">
            <Row>
                <div className="col-12">
                    <label>{t('power')}</label>
                    <input className="form-control" type="number" name="mod"  pattern="\d*"
                           defaultValue="-5" onChange={handleChange}/>
                </div>
            </Row>
        </form>
    </div>;

    return (
        <div className="d-inline">
            <ModalForm
                toggle_button={props.toggle_button || <PlusLg/>}
                toggle_button_class={props.toggle_button_class}
                title={t('add_effect')}
                content={form}
                handleForm={handleForm}
            />
        </div>
    );

}


export default AddEditEffect;
