import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import AddGear from "./AddEditGear";
import {
    ClipboardPlus,
    DashLg,
    EmojiDizzy,
    EmojiLaughing,
    GenderFemale,
    GenderMale,
    PlusLg
} from "react-bootstrap-icons";
import {Row} from "react-bootstrap";
import Gear from "./Gear";
import Effects from "./Effects";
import AddAlly from "./AddAlly";

function Battle(props) {
    const { t, i18n } = useTranslation();
    const game_context_data = useContext(GameContext);
    const [mob_rows, setMobRows] = useState([]);
    useEffect(() => {
        if(props.current_battle.is_started === false) {
            game_context_data.changeView('profile');
        } else {
            let rows = game_context_data.game.current_battle.mobs.map(function(itm, number){
                return <BattlePlayerPanel key={itm.id} player={itm} battle_mod={itm.mod} is_monster={true} battle_id={game_context_data.game.current_battle.id}/>
            });
            setMobRows(rows);
        }

    },[game_context_data]);

    function addMob() {
        let modUpdateObject = {
            "method": "addBattleMob",
            "data": {
                "battle_id": props.current_battle.id,
                "level": 1,
                "mod": 0
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    const removeAlly = () => {
        let modUpdateObject = {
            "method": "battleDeleteAlly",
            "data": {
                "battle_id": props.current_battle.id
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    const finishBattle = () => {
        if(props.current_battle.is_player_wins === true && props.current_battle.player.id === game_context_data.game.current_player_id) {
            console.log('REWARD_PAGE');
            game_context_data.changeView('reward');
        } else {
            console.log('PROFILE_PAGE');
            game_context_data.changeView('profile');
        }

        let modUpdateObject = {
            "method": "finishBattle",
            "data": {
                "battle_id": props.current_battle.id
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    const LEVELS = [...new Array(101)]
        .map((each, index) => ({ label: index, value: index }));

    return(
        <div>
            {props.current_battle.player.id !== undefined && <div>
            <div className="row g-3 align-items-center">
                <div className="btn btn-primary fw-bold mt-4">
                    {t('battle')}
                </div>
            </div>

            <div className="battle-content">

                <BattlePlayerPanel player={game_context_data.game.players[props.current_battle.player.id]} battle_mod={props.current_battle.player.mod} battle_id={props.current_battle.id}/>

                <Row className="mt-3">
                    {props.current_battle.ally.id === 0 &&
                        <div className="col-6 d-grid gap-2">
                            <AddAlly />
                        </div>
                        ||
                        <div className="col-6 d-grid gap-2">
                            <div className="btn btn-primary btn-" onClick={removeAlly}>
                                <DashLg className="me-1" />
                                {t('ally')}
                            </div>
                        </div>
                    }

                    <div className="col-6 d-grid gap-2">
                        <div className="btn btn-warning" onClick={() => addMob()}>
                            <PlusLg className="me-1" />
                            {t('mob')}
                        </div>
                    </div>
                </Row>

                <hr />

                {props.current_battle.ally.id !== 0 &&
                <div>
                    <BattlePlayerPanel player={game_context_data.game.players[props.current_battle.ally.id]} battle_mod={props.current_battle.ally.mod} battle_id={props.current_battle.id} is_ally={true}/>
                </div>
                }

                {mob_rows}
            </div>

            <div className="battle_win_stat row g-3 align-items-center">
                <a className="btn battle_win_stat_button text-center fw-bold" onClick={finishBattle}>
                    <Row>
                        <div className="col-6">
                            {t('players')}: {props.current_battle.party_power}
                        </div>
                        <div className="col-6">
                            {t('mobs')}: {props.current_battle.mobs_power}
                        </div>
                        <div className="col-12">
                            {props.current_battle.is_player_wins &&
                            <div>
                                {t('players_win')}
                                <EmojiLaughing className="ms-2" />
                            </div>
                            ||
                            <div>
                                {t('mobs_win')}
                                <EmojiDizzy className="ms-2" />
                            </div>
                            }
                        </div>
                    </Row>
                </a>
            </div>
        </div>}
        </div>
    );

}

export default Battle;

function BattlePlayerPanel(props) {
    const { t, i18n } = useTranslation();
    const game_context_data = useContext(GameContext);
    const [is_monster,setIsMonster] = useState(false);
    const [is_ally,setIsAlly] = useState(false);
    useEffect(() => {
        if(props.is_monster !== undefined){
            setIsMonster(true);
        }
        if(props.is_ally !== undefined){
            setIsAlly(true);
        }
    },[game_context_data]);

    const incrementLevel = () => {
        if(is_monster) {
            let new_level = props.player.level+1;
            setMobLevel(new_level);
        } else {
            game_context_data.update_player_level(props.player,props.player.level+1);
        }

    }
    const decrementLevel = () => {
        let new_level = props.player.level-1;
        if(is_monster) {
            setMobLevel(new_level);
        } else {
            game_context_data.update_player_level(props.player,new_level);
        }
    }

    const changeMobLevel = (e) => {
        setMobLevel(e.target.value);
    }


    const updateBattleMod = (mod) => {
        if(is_monster) {
            updateMobMod(mod);
        } else {
            if(is_ally) {
                updateAllyMod(mod);
            } else {
                updatePlayerMod(mod);
            }
        }
    }
    const removeMob = () => {
        let modUpdateObject = {
            "method": "deleteBattleMob",
            "data": {
                "battle_mob_id": props.player.id
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    const cloneMob = () => {
        let modUpdateObject = {
            "method": "cloneBattleMob",
            "data": {
                "battle_mob_id": props.player.id
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }


    function setMobLevel(level) {
        let modUpdateObject = {
            "method": "battleUpdateMobLevel",
            "data": {
                "battle_mob_id": props.player.id,
                "level": level
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    function updateMobMod(mod){
        let modUpdateObject = {
            "method": "battleUpdateMobMod",
            "data": {
                "battle_mob_id": props.player.id,
                "mod": mod
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    function updateAllyMod(mod){
        let modUpdateObject = {
            "method": "battleUpdateAllyMod",
            "data": {
                "battle_id": props.battle_id,
                "mod": mod
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    function updatePlayerMod(mod) {
        let modUpdateObject = {
            "method": "battleUpdatePlayerMod",
            "data": {
                "battle_id": props.battle_id,
                "mod": mod
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
    }

    return(
        <div>
            {props.player!==undefined &&
                <div>
                    <Row>
                        <div className={!is_monster && "col-12 text-center" || "col-6 offset-2 text-end"}>
                            <h3 className="mt-1">
                                {is_monster && t('mob')}
                                {!is_monster && (props.player.gender === 1 &&
                                    <GenderMale className="me-1" />
                                    ||
                                    <GenderFemale className="me-1" />
                                )}
                                {props.player.name}
                            </h3>
                            {!!props.player.is_changed_gender && <h6>({t('was_changed_gender')})</h6>}
                        </div>
                        {is_monster && <div className="col-4 text-end">
                            <div>
                                <a className="btn btn-success d-inline" onClick={cloneMob}>
                                    <ClipboardPlus />
                                </a>
                                <div className="btn btn-danger d-inline ms-2" onClick={removeMob}>
                                    <DashLg />
                                </div>
                            </div>
                        </div>}
                    </Row>

                    <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="text-center">
                                {t('level')}
                            </th>
                            <th scope="col" className="text-center">
                                {t('gear_power')} + {t('mod')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="vertical-middle">
                                <table>
                                    <tbody>
                                    <tr className="text-center">
                                        {!is_monster && <td className="fs-1" colSpan="2">
                                            {props.player.level}
                                        </td> }
                                        {is_monster && <td className="fs-1" colSpan="2">
                                            <select className="form-control mod_width" onChange={changeMobLevel} value={props.player.level}>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                                <option>6</option>
                                                <option>7</option>
                                                <option>8</option>
                                                <option>9</option>
                                                <option>10</option>
                                                <option>11</option>
                                                <option>12</option>
                                                <option>13</option>
                                                <option>14</option>
                                                <option>15</option>
                                                <option>16</option>
                                                <option>17</option>
                                                <option>18</option>
                                                <option>19</option>
                                                <option>20</option>
                                            </select>
                                        </td> }
                                    </tr>
                                    <tr>
                                        <td>
                                            <a className="btn btn-danger" onClick={decrementLevel}>
                                                -
                                            </a>
                                        </td>
                                        <td>
                                            <a className="btn btn-success" onClick={incrementLevel}>
                                                +
                                            </a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td className="vertical-middle">
                                <table>
                                    <tbody>
                                        <tr>
                                            {!is_monster &&
                                                <td className="fs-1 text-center">
                                                    {props.player.gear_power} + {props.battle_mod}
                                                </td>
                                            }
                                            {is_monster &&
                                                <td className="fs-1">
                                                    {props.battle_mod}
                                                </td>
                                            }
                                            <td className="ps-lg-2">
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <a className="btn btn-danger" onClick={() => updateBattleMod(-1)}>
                                                                -
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-danger" onClick={() => updateBattleMod(-2)}>
                                                                -2
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-danger" onClick={() => updateBattleMod(-3)}>
                                                                -3
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-danger" onClick={() => updateBattleMod(-5)}>
                                                                -5
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-danger" onClick={() => updateBattleMod(-10)}>
                                                                -10
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <a className="btn btn-success" onClick={() => updateBattleMod(+1)}>
                                                                +
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-success" onClick={() => updateBattleMod(+2)}>
                                                                +2
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-success" onClick={() => updateBattleMod(+3)}>
                                                                +3
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-success" onClick={() => updateBattleMod(+5)}>
                                                                +5
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-success" onClick={() => updateBattleMod(+10)}>
                                                                +10
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>}
        </div>
    );

}
