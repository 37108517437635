import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import {DashLg, GenderFemale, GenderMale, Pencil, PlusLg, Trash} from "react-bootstrap-icons";
import {Button, Modal, Row} from "react-bootstrap";
import AddEditGear from "./AddEditGear";

function AddAlly(props) {
    const game_context_data = useContext(GameContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { t } = useTranslation();
    const [rows,setRows] = useState([]);


    const addAlly = (id) => {
        let modUpdateObject = {
            "method": "battleAddAlly",
            "data": {
                "battle_id": game_context_data.game.current_battle.id,
                "ally_id": id
            }
        };

        game_context_data.send_to_socket(modUpdateObject);
        handleClose();
    }

    useEffect(() => {
        const ally_rows = [];
        for (const [key, value] of Object.entries(game_context_data.game.players)) {
            if(value.id !== game_context_data.game.current_player_id) {
                ally_rows.push(
                    <tr className="align-middle" key={value.id}>
                        <td>
                            <Row className="text-center">
                                <div className="col-12">
                                    {value.gender === 1 ?
                                        <GenderMale className="mx-1" />
                                        :
                                        <GenderFemale className="mx-1" />
                                    }
                                </div>
                                <div className="col-12">
                                    {value.name}
                                </div>
                            </Row>
                        </td>
                        <td>{value.level} / {value.total_power} / {value.total_power+game_context_data.game.current_battle.player.total_power > game_context_data.game.current_battle.mobs_power ?
                                <p className="winAlly">{value.total_power+game_context_data.game.current_battle.player.total_power}</p>
                                :
                                <p className="lossAlly">{value.total_power+game_context_data.game.current_battle.player.total_power}</p>
                            }
                            </td>
                        <td className="text-end">
                            <a className="btn btn-primary" onClick={() => addAlly(value.id)}>
                                {t('take_ally')}
                            </a>
                        </td>
                    </tr>
                );
            }

        }
        setRows(ally_rows);
    },[game_context_data]);

    return(
        <>
            <Button variant="primary" className="btn btn-primary btn-" onClick={handleShow}>
                <PlusLg className="me-1" />
                {t('ally')}
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("choose_ally")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th scope="col">{t('name')}</th>
                                <th scope="col" colSpan="2">{t('level_power_together')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                    <div>{t('monster_power')}: {game_context_data.game.current_battle.mobs_power}</div>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleClose}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}


export default AddAlly;
