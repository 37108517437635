import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";

function PlayerStat(props) {
    const game_context_data = useContext(GameContext);
    const { t, i18n } = useTranslation();

    function incrementLevel() {
        game_context_data.update_player_level(props.player, props.player.level+1);
    }
    function decrementLevel() {
        game_context_data.update_player_level(props.player, props.player.level-1);
    }
    function changeGender() {
        let genderChangeObject = {
            "method": "changeGender",
            "data": {
                "player_id": props.player.id
            }
        };
        game_context_data.send_to_socket(genderChangeObject);
    }

    function startBattle() {
        let sessionStartObject = {
            "method": "startBattle",
            "data": {
                "player_id": props.player.id
            }
        };
        game_context_data.send_to_socket(sessionStartObject);
        //game_context_data.changeView('battle');
    }

    return(
        <div>
            <div className="row text-center">
                <div className="col-6">
                    <b>{t('level')}</b>
                    <br />
                    <a className="btn btn-danger me-2" onClick={() => decrementLevel()}>
                        -
                    </a>
                    {props.player.level}
                    <a className="btn btn-success ms-2" onClick={() => incrementLevel()}>
                        +
                    </a>
                </div>
                <div className="col-6">
                    <b>{t('gear_power')}</b>
                    <br />
                    {props.player.gear_power}
                </div>
                <div className="col-6">
                    <b>{t('total_power')}</b>
                    <br />
                    {props.player.total_power}
                </div>
                <div className="col-6">
                    <b>{t('is_changed_gender')}</b>
                    <br />
                    {props.player.is_changed_gender === true ? t('Yes') : t('No')}
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6 d-grid gap-2">
                    <div className="btn btn-primary btn-" onClick={changeGender}>
                        {t('change_gender')}
                    </div>
                </div>

                <div className="col-6 d-grid gap-2">
                    <div className="btn btn-warning" onClick={startBattle}>
                        {t('start_battle')}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PlayerStat;
