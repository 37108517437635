import React, {useReducer,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import ModalForm from "./ModalForm";
import {Row} from "react-bootstrap";
import {PlusLg} from "react-bootstrap-icons";

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function AddEditGear(props) {
    const game_context_data = useContext(GameContext);
    const {t} = useTranslation();
    const [form_data, setFormData] = useReducer(formReducer, {});


    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const handleForm = () => {
        let gear_data = Object.assign({}, form_data);
        let gearModifyObject = {};

        if (props.gear !== undefined && props.gear.id > 0) {
            gear_data.gear_id = props.gear.id;
            if (gear_data.slot === undefined) {
                gear_data.slot = props.gear.slot;
            }
            if (gear_data.power === undefined) {
                gear_data.power = props.gear.power;
            }
            gearModifyObject = {
                "method": "updateGear",
                "data": gear_data
            };
        } else {
            gear_data.player_id = game_context_data.game.current_player_id;
            gearModifyObject = {
                "method": "addGear",
                "data": gear_data
            };
        }

        game_context_data.send_to_socket(gearModifyObject);
    }

    const form = <div>
        <form className="text-center">
            <Row>
                <div className="col-6">
                    <label>{t('slot')}</label>
                    <select className="form-control" name="slot" onChange={handleChange}
                            defaultValue={props.gear ? props.gear.slot : ""}>
                        <option>{t('select_slot')}</option>
                        <option value="1">{t('boots')}</option>
                        <option value="2">{t('armor')}</option>
                        <option value="3">{t('armor_with_head')}</option>
                        <option value="4">{t('head')}</option>
                        <option value="5">{t('one_hand')}</option>
                        <option value="6">{t('two_hand')}</option>
                        <option value="8">{t('sidekick')}</option>
                        <option value="9">{t('transport')}</option>
                        <option value="7">{t('without_slot')}</option>

                    </select>
                </div>
                <div className="col-6">
                    <label>{t('power')}</label>
                    <input className="form-control" type="number" name="power" pattern="\d*"
                           defaultValue={props.gear ? props.gear.power : ''} onChange={handleChange}/>
                </div>
            </Row>
        </form>
    </div>;

    return (
        <div className="d-inline">
            <ModalForm
                toggle_button={props.toggle_button || <PlusLg/>}
                toggle_button_class={props.toggle_button_class}
                title={t('add_gear')}
                content={form}
                handleForm={handleForm}
            />
        </div>
    );

}


export default AddEditGear;
