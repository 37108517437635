import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function ModalForm(props) {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleOk = () => {
        props.handleForm();
        handleClose();
    }

    return (
        <>
            <Button variant="primary" className={props.toggle_button_class || "btn btn-success ms-2"} onClick={handleShow}>
                {props.toggle_button}
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.content}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleOk}>{t('save')}</Button>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalForm;
