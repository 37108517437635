
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import React, {useReducer, useEffect, useState, useContext} from "react";
import {GameContext} from "./App";
import {Row} from "react-bootstrap";
import {GenderFemale, GenderMale} from "react-bootstrap-icons";

function SessionStart(props) {
    const game_context_data = useContext(GameContext);
    const { t, i18n } = useTranslation();
    const [players, setPlayers] = useState([{"name":"","gender":"1","is_me":true}]);
    const [players_rows, setPlayersRows] = useState([]);
    useEffect(() => {
        getPlayersRows();
    },[players]);



    function getPlayersRows() {
        let new_rows = []
        for(let i=0; i<players.length; i++){
            let name_name = "["+i+"]name";
            let gender_name = "["+i+"]gender";
            new_rows.push(
            <tr key={i}>
                <td>
                    <input className="form-control" type="text" name={i} onChange={(e) => setFormData(e,'name')}
                           placeholder={players[i].is_me ? t('its_me') : ''}
                    />
                </td>

                <td className="table_start_session_gender">
                    <select className="form-control" name={i} onChange={(e) => setFormData(e,'gender')}>
                        <option value="1">{t('gender_1')}</option>
                        <option value="2">{t('gender_2')}</option>
                    </select>
                </td>

                <td>
                    {players[i].is_me !== true &&
                        <a className="btn btn-danger" onClick={() => removePlayer(i)} remove-key={i}>X</a>
                    }
                </td>
            </tr>)
        }
        setPlayersRows(new_rows);
    }

    function addPlayer() {
        let new_players = [...players];
        new_players.push({"name":"","gender":"1","is_me":false});
        setPlayers(new_players);
    }

    function removePlayer(index) {
        let new_players = [...players];
        new_players.splice(index, 1);
        setPlayers(new_players);
    }

    function setFormData(event,array_key) {

        let value = event.target.value;
        let index = event.target.name;
        let new_players = [...players];
        new_players[index][array_key] = value;
        setPlayers(new_players);
    }

    function startSession() {
        let sessionStartObject = {
            "method": "createSession",
            "data": {
                "players": players
            }
        };
        game_context_data.send_to_socket(sessionStartObject);
    }

    return(

        <div>
            <div className="row g-3 align-items-center">
                <div className="btn btn-primary fw-bold mt-4">
                    {t('new_session')}
                </div>
            </div>

            <h3 className="text-center mt-2">
                {t('players')}
                <button className="btn btn-success ms-2" onClick={addPlayer}>+</button>
            </h3>

            <form>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{t('name')}</th>
                            <th scope="col">{t('gender')}</th>
                            <th scope="col" className="table_button" />
                        </tr>
                    </thead>
                    <tbody>
                        {players_rows}
                    </tbody>
                </table>
            </form>

            <div className="row g-3 align-items-center">
                <button className="btn btn-primary" onClick={startSession}>{t('start_session')}</button>
            </div>

        </div>
    );

}

export default SessionStart;
