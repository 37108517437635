import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import PlayerStat from "./PlayerStat";
import Gear from "./Gear";
import Effects from "./Effects";
import {GenderFemale, GenderMale} from "react-bootstrap-icons";
import {Row} from "react-bootstrap";

function Profile(props) {
    const { t, i18n } = useTranslation();
    const game_context_data = useContext(GameContext);

    const killPlayer = () => {
        let killPlayerObject = {
            "method": "killPlayer",
            "data": {
                "player_id": props.current_player.id
            }
        };
        game_context_data.send_to_socket(killPlayerObject);
    }

    return(
        <div>
            <form className="row g-3 align-items-center">
                <div className={props.current_player.gender === 1 && "btn btn-info fw-bold mt-4" || "btn btn-pink fw-bold mt-4"}>
                    <Row className="align-items-center">
                        <div className="col-6 offset-3 text-center">
                            <h3>
                                {props.current_player.gender === 1 &&
                                <GenderMale className="mx-1" />
                                ||
                                <GenderFemale className="mx-1" />
                                }
                                {props.current_player.name}
                            </h3>
                        </div>
                        <div className="col-3">
                            <a className="btn btn-warning" onClick={killPlayer}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="32px" height="32px">
                                    <path d="M0 0h512v512H0z" fill="#000" fillOpacity="0"/>
                                    <g transform="translate(0,0)">
                                        <path
                                            d="M267.313 18c-59.77.48-121.55 26.74-150.657 82.125L76.72 489.905H369.56v-.06h64.874l.783-8.5 30-331.408.405-4.218-2.938-3.095-68.75-72.094c-26.61-34.736-75.88-52.937-126.625-52.53zm-8.532 34.313c49.237.1 91.104 27.793 95.72 64.874 3.886 31.22-19.827 59.727-56.25 72.282l9.688 50.31-39.438-17.843-27.313 30.344-22.75-38.405-45.5 17.563 20.47-53c-17.87-11.625-30.013-28.117-32.376-47.094-4.922-39.55 34.388-74.78 87.814-78.688 3.34-.244 6.655-.35 9.937-.343zm41.5 49.5c-14.57 0-26.374 11.804-26.374 26.374 0 14.57 11.805 26.375 26.375 26.375s26.376-11.804 26.376-26.375c0-14.572-11.805-26.375-26.375-26.375zm-94.436 2.156c-11.424 0-20.688 9.262-20.688 20.686s9.264 20.688 20.688 20.688 20.687-9.264 20.687-20.688c0-11.426-9.262-20.687-20.686-20.687zm202.375 8.593l38.03 39.906-28.875 318.686H371.47l26.686-260.469 28.375-13.28-42.31-15.75v.093L363.186 173l39.782-9.28 5.25-51.157zm-160.095 39.062l-21.938 31.156 36.594 4.22-14.655-35.375zm-108.53 79.188c54.585 58.484 149.392 118.814 222.28 142.25-1.684 17.42-10.09 33.018-20.188 40.968-58.604-52.71-153.878-115.29-222.375-131.874-.718-21.207 7.723-40.255 20.282-51.344zm209.655 6.312c14.336 11.4 23.118 25.974 20.813 46.78-24.526 7.466-54.398 19.293-85.688 33.376-8.742-4.956-17.495-10.208-26.156-15.655 35.11-21.406 67.09-44.536 91.03-64.5zm-152.22 98.03c7.634 4.202 15.317 8.63 23 13.25-34.043 17.586-66.647 36.193-92.78 53.22-10.527-7.495-15.798-18.125-15.813-35.125 27.22-6.096 56.537-17.335 85.594-31.344z"
                                            fill="#000" fillOpacity="1"/>
                                    </g>
                                </svg>
                            </a>
                        </div>
                    </Row>
                </div>
                <PlayerStat player={props.current_player} />
                <Gear gears={props.current_player.gears} edit={true} />
                <Effects effects={props.current_player.effects} edit={true} />
            </form>
        </div>
    );

}

export default Profile;
