import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import AddEditGear from "./AddEditGear";
import {Pencil, Trash} from "react-bootstrap-icons";
import {GameContext} from "./App";
import {PlusLg} from "react-bootstrap-icons";

function Gear(props) {
    const { t, i18n } = useTranslation();
    const [gear_rows, setGearRows] = useState([]);
    useEffect(() => {
        let rows = props.gears.map(function(itm, number){
            return <GearRow key={itm.id} gear={itm} edit={props.edit} />
        });
        setGearRows(rows);
    },[props.gears]);
    return(
        <div>
            <h3 className="text-center mt-1">
                <div className="d-inline"> {t('gear')} </div>
                {props.edit && <AddEditGear />}
            </h3>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">{t('slot')}</th>
                        <th scope="col">{t('power')}</th>
                        {props.edit && <th scope="col" className="table_buttons" />}
                    </tr>
                </thead>
                <tbody>
                    {gear_rows}
                </tbody>
            </table>
        </div>
    );
}

export default Gear;

function GearRow(props) {
    const { t, i18n } = useTranslation();
    const game_context_data = useContext(GameContext);
    const handleDeleteGear = () => {
        let gearDeleteObject = {
            "method": "deleteGear",
            "data": {
                "gear_id": props.gear.id
            }
        };

        game_context_data.send_to_socket(gearDeleteObject);
    }

    function getGearText(gear) {
        switch(gear) {
            case 1:
                return t('boots');
            case 2:
                return t('armor');
            case 3:
                return t('armor_with_head');
            case 4:
                return t('head');
            case 5:
                return t('one_hand');
            case 6:
                return t('two_hand');
            case 7:
                return t('without_slot');
            case 8:
                return t('sidekick');
            case 9:
                return t('transport');
        }
    }

    return(
        <tr className="align-middle">
            <td>
                {getGearText(props.gear.slot)}
            </td>
            <td>
                {props.gear.power}
            </td>
            {props.edit && <td className="text-end">
                {props.edit && <AddEditGear toggle_button={<Pencil />} toggle_button_class="btn btn-warning" gear={props.gear}/>}
                <a className="btn btn-danger ms-1" onClick={handleDeleteGear}>
                    <Trash />
                </a>
            </td>}
        </tr>
    );
}
