import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import {Row} from "react-bootstrap";
import {EmojiLaughingFill, EmojiLaughing} from "react-bootstrap-icons";

function Win(props) {
    const game_context_data = useContext(GameContext);
    const { t } = useTranslation();

    useEffect(() => {
        if(props.player === undefined){
            game_context_data.changeView('profile');
        }
    },[props.player]);

    const reset = () => {
        let sessionResetObject = {
            "method": "resetGame",
            "data": {
                "session_id": game_context_data.game.session_id
            }
        };
        game_context_data.send_to_socket(sessionResetObject);
        game_context_data.changeView('profile');
    }

    return(
        <div className="row g-3 align-items-center">
            {props.player !== undefined && <div>
                <div className="row g-3 align-items-center">
                    <div className="btn btn-primary fw-bold mt-4">
                        {t('game_finished')}
                    </div>
                </div>

                <Row className="text-center mt-3">
                    <div className="col-12">
                        <EmojiLaughing size="180" />
                    </div>
                </Row>

                <h2 className="text-center mt-3">
                    {t('winner_name')}:<br />
                    {props.player.name}
                </h2>

                <div className="row g-3 align-items-center mt-3">
                    <button className="btn btn-warning fw-bold" onClick={reset}>
                        {t('reset_session')}
                    </button>
                </div>
            </div>}
        </div>
    );

}


export default Win;
