import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import Loader from "react-loader-spinner";

function MunchkinLoader() {
    /**
     * <div className="app app-center align-items-center">
            <Loader type="Bars" color="#00BFFF" height={80} width={80} />
        </div>
     */
    return(
        <div className="loader">
            <div className="sticky">
                <Loader type="Puff" color="#00BFFF" height={30} width={30} />
            </div>

        </div>
    );

}


export default MunchkinLoader;
