import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {GameContext} from "./App";
import PlayerShortRow from "./PlayerShortRow";
import PlayerStat from "./PlayerStat";
import Gear from "./Gear";
import Effects from "./Effects";
import {GenderFemale, GenderMale} from "react-bootstrap-icons";

function OtherPlayers(props) {
    const { t, i18n } = useTranslation();
    const [other_players, setOtherPlayers] = useState([]);
    const game_context_data = useContext(GameContext);
    useEffect(() => {
        let rows = [];
        Object.keys(props.players).map((key) => {
            rows.push(<OtherPlayersRow key={key} player={props.players[key]}/>);
        });
        setOtherPlayers(rows);
    },[game_context_data]);
    return(
        <div className="row g-3 align-items-center">
            <div className="btn btn-primary fw-bold mt-4">
                {t('players')}
            </div>
            {other_players}
        </div>
    );

}

export default OtherPlayers;

function OtherPlayersRow(props) {
    const { t } = useTranslation();
    const [show_full, setShowFull] = useState(false);
    function toggleShow() {
        setShowFull(!show_full);
    }
    return(
        <div className={props.player.gender === 1 ? "player_item btn-info" : "player_item btn-pink"} onClick={toggleShow}>
            <div className="row align-items-center">
                <div className="col-3 text-center">
                    {props.player.gender === 1 ?
                        <GenderMale className="mx-1" />
                    :
                        <GenderFemale className="mx-1" />
                    }
                    <br />
                    {props.player.name}
                </div>
                <div className="col-9 text-center">
                    <div className="row">
                        <div className="col-6">
                            <b>{t('level')}</b>
                            <br />
                            {props.player.level}
                        </div>
                        <div className="col-6">
                            <b>{t('gear_power')}</b>
                            <br />
                            {props.player.gear_power}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <b>{t('total_power')}</b>
                            <br />
                            {props.player.total_power}
                        </div>
                        <div className="col-6">
                            <b>{t('is_changed_gender')}</b>
                            <br />
                            {props.player.is_changed_gender === true && t('Yes') || t('No')}
                        </div>
                    </div>
                </div>
            </div>

            {show_full &&
                <div>
                    <Gear gears={props.player.gears} />
                    <Effects effects={props.player.effects} />
                </div>
            }
        </div>
    );

}
