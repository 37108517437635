import React, {useState,useContext,useEffect} from 'react';
import '../Styles/App.css';
import {useTranslation} from "react-i18next";
import {Pencil, PlusLg, Trash} from "react-bootstrap-icons";
import AddEditEffect from "./AddEditEffect";
import AddEditGear from "./AddEditGear";
import {GameContext} from "./App";

function Effects(props) {
    const { t, i18n } = useTranslation();
    const [effect_rows, setEffectRows] = useState([]);
    useEffect(() => {
        let rows = props.effects.map(function(itm,number){
            return <EffectRow key={number} effect={itm} edit={props.edit} />
        });
        setEffectRows(rows);
    },[props.effects]);
    return(
        <div>
            <h3 className="text-center">
                {t('effects')}
                {props.edit && <AddEditEffect />}
            </h3>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">{t('mod')}</th>
                        <th scope="col" colSpan={props.edit && 2 || 1}>{t('battle_count')}</th>
                    </tr>
                </thead>
                <tbody>
                    {effect_rows}
                </tbody>
            </table>
        </div>
    );

}

export default Effects;

function EffectRow(props) {
    const game_context_data = useContext(GameContext);
    const handleDeleteEffect = () => {
        let gearDeleteObject = {
            "method": "deleteEffect",
            "data": {
                "effect_id": props.effect.id
            }
        };

        game_context_data.send_to_socket(gearDeleteObject);
    }
    return(
        <tr className="align-middle">
            <td>
                {props.effect.mod}
            </td>
            <td>
                {props.effect.battle_count}
            </td>
            {props.edit && <td className="table_buttons text-end">
                <AddEditEffect toggle_button={<Pencil />} toggle_button_class="btn btn-warning" effect={props.effect}/>

                <a className="btn btn-danger ms-1" onClick={handleDeleteEffect}>
                    <Trash />
                </a>
            </td>}
        </tr>
    );

}
